// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDn5z2E5JZWugc_F3AaOpbbkTkqtswLhx0",
    authDomain: "power-up---gallery.firebaseapp.com",
    projectId: "power-up---gallery",
    storageBucket: "power-up---gallery.appspot.com",
    messagingSenderId: "49944523243",
    appId: "1:49944523243:web:d9f4822d1c6f14fe6529ae"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Storage and get a reference to the service
export const storage = getStorage(app);
//
export const db = getFirestore(app); 